import { createBrowserHistory } from 'history'

import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { createReduxHistoryContext } from 'redux-first-history'

import {
  api as watchApi,
  reducer as watchesReducer,
  locationSync as watchesLocationSync,
} from '../features/watches/watchesSlice'

import {
  api as campgroundApi,
  reducer as campgroundsReducer,
} from '../features/create/campgroundsSlice'

import {
  reducer as authReducer,
} from '../features/auth/authSlice'

import { createLocationSyncMiddleware } from './locationSyncMiddleware'

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

const locationSyncMiddleware = createLocationSyncMiddleware({
  syncs: [
    { path: '/watches', ...watchesLocationSync },
  ],
})

export const store = configureStore({
  reducer: {
    [watchApi.reducerPath]: watchApi.reducer,
    [campgroundApi.reducerPath]: campgroundApi.reducer,
    campgrounds: campgroundsReducer,
    watches: watchesReducer,
    router: routerReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      locationSyncMiddleware,
      watchApi.middleware,
      campgroundApi.middleware,
      routerMiddleware,
    )
})

setupListeners(store.dispatch)
export const history = createReduxHistory(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
