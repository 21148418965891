import { GraphQLClient } from 'graphql-request'

import type { RootState } from './store'
import { tokenSelector } from '../features/auth/authSlice'
import { url as endpoint} from '../graphql/config'

export interface GraphQLBaseQueryProps<VariablesType> {
  query: string,   // GraphQL query
  variables?: VariablesType, // GraphQL args
  key?: string,    // optional key to allow multiple result sets to be managed
}

export const graphQLBaseQuery = () =>
  async ({ query, variables, key} : GraphQLBaseQueryProps<any>, api: any) => {
    const token = tokenSelector()(api.getState() as RootState)

    if (!token) {
      throw new Error("null token in graphql call");
    }

    console.log('API query', query, variables, key)

    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: token,
      },
    })

    try {
      const data = await graphQLClient.request(query, variables)
      console.log('API results', data)
      return {
        data: data
      }
    } catch (error) {
      console.log('API error', error)
      return {
        error: error
      }
    }
  }