import React from 'react'

import { ActionIcon, Container } from '@mantine/core'

import { PagedTable } from '../../components/pagedTable'
import { Trash } from 'tabler-icons-react'

import {
  useFetchWatchesQuery,
  useDeleteWatchMutation,
} from './watchesSlice'

import { Watch } from './index'

const headerRow = (
  <tr>
    <th>&nbsp;</th>
    <th>Start</th>
    <th>Nights</th>
    <th>People</th>
    <th>Id</th>
  </tr>
)

interface WatchRowProps {
  item: Watch,
  hovered: boolean,
  selected: boolean,
}
interface WatchRowReturner {
  (arg0: WatchRowProps): React.ReactNode,
}

interface DeleteMethod {
  (arg0: any): void
}

interface WatchedRowReturnerProps {
  (deleteMethod: DeleteMethod): WatchRowReturner,
}

const watchRow: WatchedRowReturnerProps = (deleteMethod) => {
  return ({item: watch, hovered, selected, ...others}) => {
    return (
      <tr
        {...others}
      >
        <td>
          {hovered ?
            <ActionIcon
              size='sm'
              variant='light'
              onClick={() => deleteMethod(watch)}
            >
              <Trash />
            </ActionIcon>
          :
            <Container size='sm' />
          }
        </td>
        <td>{watch.startDates[0]}</td>
        {
          (watch.minNights === watch.MaxNights)
          ? <td>{watch.minNights}</td>
          : <td>{watch.minNights}-{watch.maxNights}</td>
        }
        {
          (watch.minPeople === watch.MaxPeople)
          ? <td>{watch.minPeople}</td>
          : <td>{watch.minPeople}-{watch.maxPeople}</td>
        }
        <td>{selected ? 'selected' : watch.id}</td>
      </tr>
    )
  }
}

export const WatchesTable: React.FC = () => {
  const [ deleteWatch ] = useDeleteWatchMutation()
  const { data: watches, isError, isLoading } = useFetchWatchesQuery()

  if (isLoading) {
    return (
      <div>Loading</div>
    )
  }

  if (isError) {
    return (<div>{isError}</div>)
  }

  if (!watches) {
    return (<div>No Data</div>)
  }

  return (
    <PagedTable
      items={watches}
      headerRow={headerRow}
      itemRow={watchRow(deleteWatch)}
      getKey={item => item.id}
    />
  )
}
