import { createApi } from '@reduxjs/toolkit/query/react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { graphQLBaseQuery } from '../../app/graphQLBaseQuery'

import {
  createWatch as createWatchMutation,
  deleteWatch as deleteWatchMutation,
  createCampgroundWatch as createCampgroundWatchMutation,
} from '../../graphql/mutations'

import { Watch } from './index'
import { listWatches } from '../../graphql/queries'

//
// API
//
export const api = createApi({
  reducerPath: 'watchApi',
  tagTypes: ['Watch', 'CampgroundWatch'],
  baseQuery: graphQLBaseQuery(),
  endpoints: (builder) => ({
    fetchWatches: builder.query<Watch[], void>({
      query: () => ({
        query: listWatches,
      }),
      providesTags: (result) =>
        result
        ? [
            ...result.map(({ id }) =>
              ({ type: 'Watch' as 'Watch', id: id })),
            {type: 'Watch' as 'Watch', id: 'LIST'}
          ]
        : [{type: 'Watch' as 'Watch', id: 'LIST'}],
      transformResponse: (response) => response.listWatches.items,
    }),

    createWatch: builder.mutation({
      query: (data) => ({
        query: createWatchMutation,
        variables: { input: data },
      }),
      invalidatesTags: [{type: 'Watch', id: 'LIST'}],
    }),

    deleteWatch: builder.mutation({
      query: (watch) => ({
        query: deleteWatchMutation,
        variables: { input: {
          id: watch.id,
        } },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Watch', id: arg.id }],
    }),

    createCampgroundWatch: builder.mutation({
      query: (data) => ({
        query: createCampgroundWatchMutation,
        variables: { input: data },
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          {type: 'CampgroundWatch', id: 'LIST'},
          {type: 'Watch', id: result.createCampgroundWatch.id},
        ]
      },
    }),
  })
})

export const {
  useFetchWatchesQuery,
  useCreateWatchMutation,
  useDeleteWatchMutation,
  useCreateCampgroundWatchMutation,
} = api

//
// Watches Slice
//
export interface WatchesUrlState {
  createOpen: boolean,
  minPeople?: number | null,
  maxPeople?: number | null,
  minNights?: number | null,
  maxNights?: number | null,
  startDateStart?: number | null,
  startDateEnd?: number | null,
  campgrounds?: string[] | null,
}
interface WatchesSliceState {
  url: WatchesUrlState,
}
export const watchesSlice = createSlice({
  name: 'watches',
  initialState: {
    url: {
      createOpen: false,
      minNights: 4,
      maxNights: 4,
      minPeople: 4,
      maxPeople: 4,
      startDateStart: null,
      startDateEnd: null,
      campgrounds: [],
    },
  } as WatchesSliceState,
  reducers: {
    setCreateOpen: (state, action: PayloadAction<boolean>) => {
      state.url.createOpen = action.payload
    },
    setUrlState: (state, action: PayloadAction<WatchesUrlState>) => {
      state.url = action.payload
    }
  },
})

export const {
  setUrlState,
  setCreateOpen,
} = watchesSlice.actions

export const createOpenSelector = () =>
  (state: any) => state.watches.url.createOpen
export const createUrlSelector = () =>
  (state: any) => state.watches.url

//
// Location Sync
///
const encode = (value: any) => {
  const pathname = value.createOpen ? '/watches/create' : '/watches'

  let terms = {}
  if (value.createOpen) {
    terms['minp'] = value.minPeople
    terms['maxp'] = value.maxPeople
    terms['minn'] = value.minNights
    terms['maxn'] = value.maxNights
    if (value.startDateStart) {
      terms['ss'] = value.startDateStart.valueOf()
    }
    if (value.startDateEnd) {
      terms['se'] = value.startDateEnd.valueOf()
    }
    if (value.campgrounds && value.campgrounds.length) {
      terms['c'] = value.campgrounds.join(',')
    }
  }

  const params = new URLSearchParams(terms)
  return {pathname, search: params.toString(), hash: ''}
}

const decode = ({pathname, search, hash}: {pathname: string, search: string, hash:string}) => {
  const params = new URLSearchParams(search)

  return {
    createOpen: pathname.endsWith('/create'),
    minPeople: params.get('minp') && parseInt(params.get('minp')!),
    maxPeople: params.get('maxp') && parseInt(params.get('maxp')!),
    minNights: params.get('minn') && parseInt(params.get('minn')!),
    maxNights: params.get('maxn') && parseInt(params.get('maxn')!),
    startDateStart: params.get('ss') && parseInt(params.get('ss')!),
    startDateEnd: params.get('se') && parseInt(params.get('se')!),
    campgrounds: params.get('c') && params.get('c')!.split(','),
  }
}

export const locationSync = {
  encode: encode,
  decode: decode,
  selector: createUrlSelector(),
  action: setUrlState,
}

export const reducer = watchesSlice.reducer