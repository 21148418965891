import React from 'react'
import { useRef, useState, useEffect } from 'react'

import CSS from 'csstype'
import Fuse from 'fuse.js'

import { useSelector, useDispatch } from 'react-redux'

import {
  Group,
  Stack,
  Image,
  Table,
  Text,
  ThemeIcon,
  TextInput,
  ScrollArea,
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'

import {
  Search,
  PhotoOff,
} from 'tabler-icons-react'

import {
  setSelectedCampground,
  selectedCampgroundSelector,
  campgroundsSearchResultsSelector,
} from './campgroundsSlice'

import { Campground } from './index'

interface CampgroundsTableProps {
  style: CSS.Properties,
}

export const CampgroundsTable: React.FC<CampgroundsTableProps> = ({style}) => {
  const dispatch = useDispatch()
  const [ searchTerm, setSearchTerm ] = useState<string>('')
  const [filteredResults, setFilteredResults] = useState<Campground[] | Fuse.FuseResult<Campground>[]>()

  const searchResults = useSelector(campgroundsSearchResultsSelector())
  const selectedCampground = useSelector(selectedCampgroundSelector())
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200)

  const fuse = useRef<Fuse<Array<Campground> > >()

  function onSearchChange(event: React.FormEvent<HTMLInputElement>) {
    setSearchTerm(event.currentTarget.value)
  }

  // Row filtering logic
  useEffect(() => {
    fuse.current = new Fuse(searchResults, {
      threshold: 0.5,
      includeScore: true,
      includeMatches: true,
      ignoreLocation: true,
      minMatchCharLength: 2,
      keys: ['name'],
    })

    if (debouncedSearchTerm) {
      setFilteredResults(fuse.current.search(debouncedSearchTerm))
    } else {
      setFilteredResults(undefined)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults])

  useEffect(() => {
    if (debouncedSearchTerm && fuse.current) {
      setFilteredResults(fuse.current.search(debouncedSearchTerm))
    } else {
      setFilteredResults(undefined)
    }
  }, [debouncedSearchTerm])

  const noFilterRows = () => {
    if (!searchResults)
      return

    return searchResults.map((cg: Campground) => (
      <tr
        key={`${cg.id}`}
        onClick={() => dispatch(setSelectedCampground(cg))}
      >
        <td>
          {cg.thumbnail ? <Image src={cg.thumbnail} /> : <ThemeIcon size="xl" variant="light"><PhotoOff /></ThemeIcon>}
        </td>
        <td width='100%'>
          <Text size='xs' color={(selectedCampground === cg) ? 'red' : undefined}>
            {cg.name}
          </Text>
        </td>
      </tr>
    ))
  }

  interface HighlightParams {
    (value: string, indices?: ReadonlyArray<[number, number]>, i?: number): string | React.ReactNode,
  }

  const highlight: HighlightParams = (value, indices=[], i=1) => {
    const pair = indices[indices.length - i];
    return !pair ? value : (
      <>
        {highlight(value.substring(0, pair[0]), indices, i+1)}
        <u>{value.substring(pair[0], pair[1]+1)}</u>
        {value.substring(pair[1]+1)}
      </>
    )
  }

  const filteredRows = () => {
    const filteredMatches = filteredResults as unknown as Fuse.FuseResult<Campground>[]
    const fRows = filteredMatches.map((result: Fuse.FuseResult<Campground>) => (
      <tr key={`${result.item.id}`} onClick={() => dispatch(setSelectedCampground(result.item))}>
        <td>
          <Text size='xs' color={(selectedCampground === result.item) ? 'red' : undefined}>
            {highlight(result.matches![0].value!, result.matches![0].indices)}
          </Text>
        </td>
      </tr>
    ))

    if (fRows)
      return fRows

    return noFilterRows()
  }

  return (
    <Stack style={style}>
      <Group position='apart'>
        <Text size='lg' color='gray'>Parks</Text>
        <TextInput
          size='xs'
          style={{flex: 1}}
          placeholder='Search'
          icon={<Search size={14} />}
          onChange={onSearchChange}
        />
      </Group>
      <ScrollArea styles={{ viewport: { height: 'calc(100vh - 250px)' }}} >
        <Table highlightOnHover sx={{size: 'small' }}>
          <tbody>
            { filteredResults ? filteredRows() : noFilterRows() }
          </tbody>
        </Table>
      </ScrollArea>
    </Stack>
  )
}