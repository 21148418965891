import React from 'react'
import { useState } from 'react'

import CSS from 'csstype'

import { Table, Pagination, Stack, Group } from '@mantine/core'

interface PagedTableProps {
  items: any[],
  headerRow: React.ReactNode,
  getKey: (item: any) => string,
  itemRow: (item: any) => React.ReactNode,

  style?: CSS.Properties,
  selected?: any,
  onSelection?: (item: any) => void,
}

export const PagedTable : React.FC<PagedTableProps> = ({
  items = [],
  headerRow,
  itemRow,
  getKey,
  style,
  selected,
  onSelection,
}) => {
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage] = useState<number>(10)
  const [hover, setHover] = useState<string>()

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleMouseIn = (key: string) =>
    () => {
      setHover(key)
    }

  const handleMouseOut = (key: string) =>
    () => {
      setHover(undefined)
    }

  const handleClick = (item: number) =>
    () => {
      if (selected === item)
        return
      onSelection?.(item)
    }

  return (
    <Stack style={style} py='md'>
      <Table
        sx={{ size: 'small' }}
        highlightOnHover
      >
        <thead>
          {headerRow}
        </thead>
        <tbody>
          {(rowsPerPage > 0
            ? items.slice((page - 1) * rowsPerPage, page * rowsPerPage)
            : items
            ).map((item) => (
              itemRow({
                item: item,
                key: getKey(item),
                onClick: handleClick(item),
                onMouseOut: handleMouseOut(getKey(item)),
                onMouseOver: handleMouseIn(getKey(item)),
                hovered: hover === getKey(item) ? 'true' : undefined,
                selected: selected === item ? true : undefined,
              })
            )
          )}
        </tbody>
      </Table>
      <Group position='center'>
        <Pagination
          total={Math.ceil(items.length/rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </Group>
    </Stack>
  )
}
