import React from 'react'

import {
  Card,
  Image,
  Text,
  Group,
  Button,
  ThemeIcon,
  Center,
  useMantineTheme,
} from '@mantine/core'

import {
  PhotoOff,
} from 'tabler-icons-react'

import { Campground } from './index'

interface CampgroundCardProps {
  campground: Campground,
}

export const CampgroundCard: React.FC<CampgroundCardProps> = ({
  campground,
}) => {
  const theme = useMantineTheme()

  const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7]

  if (!campground) {
    return <div>No campground selected</div>
  }

  return <Card shadow="sm" p="lg" sx={{width: 300}}>
    <Card.Section>
      <Center>
        {
          campground.thumbnail
          ? <Image height={160} src={campground.thumbnail} />
          : <ThemeIcon size={160} variant="light">
              <PhotoOff />
            </ThemeIcon>
        }
      </Center>
    </Card.Section>

    <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
      <Text
        size="md"
        weight="bold"
        style={{ fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif' }}
      >
        {campground.name}
      </Text>
    </Group>

    <Text size="sm" style={{ color: secondaryColor, lineHeight: 1.5 }}>
      With Fjord Tours you can explore more of the magical fjord landscapes with tours and
      activities on and around the fjords of Norway
    </Text>

    <Button variant="light" color="blue" fullWidth style={{ marginTop: 14 }}>
      See details
    </Button>
  </Card>
}