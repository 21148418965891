import { AnyAction, ActionCreator, Middleware } from 'redux'
import { deepEqual } from 'fast-equals'
import { LOCATION_CHANGE, push } from 'redux-first-history'

export interface SyncLocation {
  pathname: string,
  search: string,
  hash: string,
}

export interface LocationSyncMiddlewareSync {
  encode: (state: any) => SyncLocation,
  decode: (location: SyncLocation) => any,
  selector: (state: any) => any,
  action: ActionCreator<AnyAction>,
  path: string,
}

export interface LocationSyncMiddlewareConfig {
  routerReducerKey?: string,
  syncs: Array<LocationSyncMiddlewareSync>,
}

export const createLocationSyncMiddleware = (options : LocationSyncMiddlewareConfig) => {
  const defaults = {
    routerReducerKey: 'router',
  }
  const config = {
    ...defaults,
    ...options,
  }

  const middleware : Middleware = (store) => (next) => (action) => {
    const results  = next(action)

    // If we change location sync to state
    if (action.type === LOCATION_CHANGE) {
      const state = store.getState()

      // Update the state for any changes from the url
      for (const {decode, selector, action: act, path} of config.syncs) {
        if (path && !action.payload.location.pathname.startsWith(path)) {
          console.log('NO MATCH', path, action.payload.location)
          continue
        }
        const value = decode(action.payload.location)
        const old = selector(state)
        if (value && !deepEqual(value, old)) {
          console.log('sync from url to store', old, value)
          store.dispatch(act(value))
        }
      }
    }

    // If we change state sync to location
    if (action.type.startsWith('watches')) {
      interface fixLocationProps {
        pathname: string,
        search: string,
        hash: string,
      }

      // Normalize the form of search and hash
      const fixLocation = ({pathname, search, hash} : fixLocationProps) => ({
        pathname: pathname,
        search: search.replace(/^\?/, ''),
        hash: hash.replace(/^#/, ''),
      })

      const state = store.getState()

      // Update the url for any changes in the state
      config.syncs.forEach(({encode, selector}) => {
        const value = fixLocation(encode(selector(state)))
        // Pull just the location props from routing
        const old = fixLocation(state[config.routerReducerKey].location)
        if (!deepEqual(value, old)) {
          console.log('sync from store to url', old, value)
          store.dispatch(push(value))
        }
      })
    }

    return results
  }

  return middleware
}
