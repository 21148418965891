import React from 'react'
import {
  useEffect,
  useState,
  useRef,
  Children,
  isValidElement,
  cloneElement,
} from 'react'

import {CampgroundCard} from './campgroundCard'

import { useSelector, useDispatch } from 'react-redux'

import { Wrapper, Status } from '@googlemaps/react-wrapper'

import { Text } from '@mantine/core'

import { CampgroundMarker } from './campgroundMarker'
import { useDeepCompareEffectForMaps } from './mapUtils'

import {
  setSelectedCampground,
  selectedCampgroundSelector,
  campgroundsSearchResultsSelector,
} from './campgroundsSlice'

import { Campground } from './index'

const render = (status: Status) => {
  return <Text>{status} ...</Text>
}

interface MapProps extends google.maps.MapOptions {
  style?: { [key: string]: string }
  onClick?: (e: google.maps.MapMouseEvent) => void
  onIdle?: (map: google.maps.Map) => void
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  style,
  children,
  ...options
}) => {
  const mapRef = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<google.maps.Map>()

  useEffect(() => {
    if (mapRef.current && !map) {
      console.log('creating map')
      window.campwatch.map = new window.google.maps.Map(mapRef.current, {
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
      })
      setMap(window.campwatch.map)
    }
  }, [mapRef, map])

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      )

      if (onClick) {
        map.addListener("click", onClick)
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map))
      }
    }
  }, [map, onClick, onIdle])

  return (
    <>
      <div ref={mapRef} style={style} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { map })
        }
        return
      })}
    </>
  )
}

interface CampgroundsMapProps {
  [x:string]: any,
}

export const CampgroundsMap: React.FC<CampgroundsMapProps> = (props) => {
  const dispatch = useDispatch()
  const searchResults = useSelector(campgroundsSearchResultsSelector())
  const selectedCampground = useSelector(selectedCampgroundSelector())
  const [zoom] = useState<number>(3)
  const [center] = useState<google.maps.LatLngLiteral>({ lat: 0, lng: 0 })
  const currentSelection = useRef<Campground>()

  function onClick(event: google.maps.MapMouseEvent) {
    console.log('onClick', event)
  }

  useEffect(() => {
    if (!selectedCampground || currentSelection.current === selectedCampground)
      return

    window.campwatch.map.panTo({
      lat: selectedCampground.latitude,
      lng: selectedCampground.longitude,
    })
    currentSelection.current = selectedCampground
  }, [selectedCampground])

  return (
    <Wrapper apiKey='AIzaSyAlsFZ-GPi7KYQXltvHC3eF-jFRM5MwzeY' render={render}>
      <Map
        zoom={zoom}
        center={center}
        onClick={onClick}
        {...props}
      >
        {searchResults && searchResults.map((campground: Campground) => (
          <CampgroundMarker
            open={selectedCampground === campground}
            key={campground.id}
            title={campground.name}
            icon={{
              url: campground.source === 'api.amp.active.com' ? 'icons/mountain.png' : 'icons/tent.png',
              scaledSize: new google.maps.Size(32, 32),
            }}
            clickable
            optimized
            onClick={() => dispatch(setSelectedCampground(campground))}
            position={{
              lat: campground.latitude,
              lng: campground.longitude,
            }}
          >
            <CampgroundCard campground={selectedCampground} />
          </CampgroundMarker>
        ))}
      </Map>
    </Wrapper>
  )
}
