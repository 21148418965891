import React from 'react'
import { useState, useEffect, useRef, forwardRef } from 'react'

import {
  Group,
  SelectItem,
  MultiSelect,
  MultiSelectProps,
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'

import { useLazyFetchCampgroundsQuery } from './campgroundsSlice'

import { Campground } from './index'

interface CampgroundSelectItemProps {
  label: string,
  campground: Campground,
}

const CampgroundSelectItem = forwardRef<HTMLDivElement, CampgroundSelectItemProps>(({ campground, label, ...others }, ref) => {
  if (campground) {
    return (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>{campground.name}{(campground.state) && `, ${campground.state}`}</div>
          {/* {(campground.waterfront) && <Swimming/>} */}
        </Group>
      </div>
    )
  } else {
    return (
      <div ref={ref} {...others}>{label}</div>
    )
  }
})

export interface CampgroundsMultiSelectProps extends
  Omit<MultiSelectProps, 'data' | 'searchable' | 'onSearchChange' | 'onChange' | 'filter'>
{
  key?: string,
  onChange(value: Campground[]) : void,
}

interface CampgroundMap { [key: string]: {campground: Campground, selected: boolean }}

export const CampgroundsMultiSelect = ({key, onChange: onChangeArg,...others}: CampgroundsMultiSelectProps) => {
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery] = useDebouncedValue<string>(query, 300)
  const [ trigger, results ] = useLazyFetchCampgroundsQuery()
  const [ data, setData ] = useState<SelectItem[]>([])
  const idToCampgroundMap = useRef<CampgroundMap>({})
  const selectedCampgrounds = useRef<Campground[]>([])

  const campgroundToItem = (campground: Campground, group?: string): SelectItem => ({
    label: campground.name,
    value: campground.id,
    campground: campground,
    group: group,
})

  useEffect(() => {
    if (debouncedQuery) {
      trigger({
        variables: {
          name: debouncedQuery,
        },
        key: key,
      }, true /* preferCacheValue */)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery])

  useEffect(() => {
    switch (results.status) {
      case 'uninitialized':
        setData([
          {label: 'No Data', value: 'nodata', group: selectedCampgrounds.current.length ? 'Status' : undefined, disabled: true},
          ...selectedCampgrounds.current.map((cg) => campgroundToItem(cg, 'Selected')),
        ])
        break
      case 'pending':
        setData([
          {label: 'Loading...', value: 'loading', group: selectedCampgrounds.current.length ? 'Status' : undefined, disabled: true},
          ...selectedCampgrounds.current.map((cg) => campgroundToItem(cg, 'Selected')),
        ])
        break
      case 'fulfilled':
        // Reset the map of ids to campgrounds to results + selection
        idToCampgroundMap.current = [...selectedCampgrounds.current, ...results.data!].reduce((map, item) => {
          map[item.id] = { campground: item, selected: false}
          return map
        }, {})
        // Mark selection as selected
        selectedCampgrounds.current.forEach(({id}) => {
          idToCampgroundMap.current[id].selected = true
        })
        // Update data
        setData(getCurrentData())
        break
      default:
        setData([{label: `Unknown status: ${results.status}.`, value: 'unknown', disabled: true}])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  const getCurrentData = () => {
    const data = Object.values(idToCampgroundMap.current).map(({campground, selected}) => (
      campgroundToItem(
        campground,
        selected ? 'Selected' : undefined,
      )
    ))
    console.log('getCurrentData', data)
    return data
  }

  const handleChange = (value: string[]) => {
    // Clear selected from the old value
    if (selectedCampgrounds.current.length) {
      selectedCampgrounds.current.forEach(({id}) => {
        idToCampgroundMap.current[id].selected = false
      })
    }
    // Set selected and update the value
    selectedCampgrounds.current = value.map((id) => {
      idToCampgroundMap.current[id].selected = true
      return idToCampgroundMap.current[id].campground
    })

    setData(getCurrentData())
    onChangeArg && onChangeArg(selectedCampgrounds.current)
  }

  return (
    <MultiSelect
      searchable
      data={data}
      itemComponent={CampgroundSelectItem}
      onSearchChange={setQuery}
      onChange={handleChange}
      filter={(value: string, selected: boolean, item: SelectItem) => { return true}}
      {...others}
    />
  )
}