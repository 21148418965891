import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'

import {
  graphQLBaseQuery,
  GraphQLBaseQueryProps,
} from '../../app/graphQLBaseQuery'

import { Campground } from './index'

import {
  campgrounds
} from '../../graphql/queries'

//
// API
//
export interface CampgroundsVariables {
  name?: string,
  longitude?: number,
  latitude?: number,
}

export interface CampgroundsQueryProps
  extends Omit<GraphQLBaseQueryProps<CampgroundsVariables>, 'query'> {}

export const api = createApi({
  reducerPath: 'campgroundApi',
  tagTypes: ['Campground'],
  baseQuery: graphQLBaseQuery(),
  endpoints: (builder) => ({
    fetchCampgrounds: builder.query<Campground[], CampgroundsQueryProps>({
      query: ({variables, key}) => ({
        key: key,
        query: campgrounds,
        variables: variables,
      }),
      providesTags: (result) => (
        result
        ? [
            ...result.map(({ id }) =>
              {
                return ({
                  type: 'Campground' as 'Campground',
                  id: id,
                })
              }),
            {type: 'Campground' as 'Campground', id: 'LIST'},
          ]
        : [{type: 'Campground' as 'Campground', id: 'LIST'}]
      ),
      transformResponse: (response) => {
        return response.campgrounds.map((cg: any) => ({
          waterfront: cg.waterfront || false,
          ...cg
        }))
      }
    }),
  })
})

//
// Slice
//
interface CampgroundsSliceState {
  searchResults: Campground[],
  selectedCampground?: Campground,
}
export const campgroundsSlice = createSlice({
  name: 'campgrounds',
  initialState: {
    searchResults: [],
    selectedCampground: undefined,
  } as CampgroundsSliceState,
  reducers: {
    setSearchResults: (state, action: PayloadAction<Array<Campground> >) => {
      state.searchResults = action.payload
    },
    setSelectedCampground: (state, action: PayloadAction<Campground>) => {
      state.selectedCampground = action.payload
    },
  },
})

export const campgroundsSearchResultsSelector = () =>
  (state: any) => state.campgrounds.searchResults
export const selectedCampgroundSelector = () =>
  (state: any) => state.campgrounds.selectedCampground

export const {
  useFetchCampgroundsQuery,
  useLazyFetchCampgroundsQuery,
} = api

export const {
  setSearchResults,
  setSelectedCampground,
} = campgroundsSlice.actions

export const reducer = campgroundsSlice.reducer
