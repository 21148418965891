import React from 'react'
import { forwardRef, useState, useEffect }from 'react'

import { useAuth0, User as Auth0User } from "@auth0/auth0-react"

import gravatar from 'gravatar-api'

import {
  UnstyledButton,
  Group,
  Avatar,
  Text,
  Box,
  Menu,
  useMantineColorScheme,
} from '@mantine/core'

import {
  ChevronRight,
  Logout,
  Sun,
  Moon,
} from 'tabler-icons-react'

interface UserButtonProps {
  image?: string,
  name?: string,
  email?: string,
  icon?: number,
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps> (
  ({ image, name, email, icon, ...others }, ref) => (
    <UnstyledButton
      ref={ref}
      sx={(theme) => ({
        display: 'block',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
      })}
      {...others}
    >
      <Group>
        <Avatar src={image} radius='xl' />

        <div style={{ flex: 1 }}>
          <Text size='sm' weight={500}>
            {name}
          </Text>

          <Text color='dimmed' size='xs'>
            {email}
          </Text>
        </div>

        {icon || <ChevronRight size={16} />}
      </Group>
    </UnstyledButton>
  )
)

export const User: React.FC = () => {
  const [user, setUser] = useState<Auth0User>()
  const [gravatarUrl, setGravatarUrl] = useState<string>('')
  const { logout, user: authUser } = useAuth0()

  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const dark = colorScheme === 'dark'

  useEffect(() => {
    if (authUser) {
      console.log('User.authUser', authUser)
      setUser(authUser)
      setGravatarUrl(gravatar.imageUrl({ email: authUser.email }))
    }
  }, [authUser])

  return (
    <Box>
      <Group position='center'>
        <Menu
          withArrow
          placement='end'
          position='right'
          control={
            <UserButton
              image={gravatarUrl}
              name={user?.nickname}
              email={user?.email}
            />
          }
        >
          <Menu.Label>User Menu</Menu.Label>
          <Menu.Item
            icon={<Logout size={14} />}
            onClick={() => {logout({ returnTo: window.location.origin })}}
          >
            Sign Out
          </Menu.Item>
          <Menu.Item
            icon={dark ? <Sun size={14} /> : <Moon size={14} />}
            onClick={() => toggleColorScheme()}
          >
            Toggle Dark Mode
          </Menu.Item>
        </Menu>
      </Group>
    </Box>
  )
}
