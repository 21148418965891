import React from 'react'
import { useEffect, useRef } from 'react'
import { renderToString } from 'react-dom/server'

interface CampgroundMarkerProps extends google.maps.MarkerOptions {
  open: boolean,
  onClick?(): void,
}

export const CampgroundMarker: React.FC<CampgroundMarkerProps> = ({
  children,
  open,
  onClick,
  ...markerOptions
}) => {
  const marker = useRef<google.maps.Marker>()
  const infoWindow = useRef<google.maps.InfoWindow>()

  useEffect(() => {
    if (!marker.current) {
      marker.current = new window.google.maps.Marker()
      if(onClick)
        marker.current.addListener("click", onClick)
    }

    // remove marker from map on unmount
    return () => {
      if (marker.current) {
        marker.current.setMap(null)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker])

  useEffect(() => {
    if (marker.current) {
      marker.current.setOptions(markerOptions)
    }
  }, [marker, markerOptions])

  useEffect(() => {
    if (!infoWindow.current) {
      infoWindow.current = new window.google.maps.InfoWindow()
    }
  }, [infoWindow])

  useEffect(() => {
    if (open) {
      infoWindow.current?.open({
        anchor: marker.current,
        map: window.campwatch.map,
        shouldFocus: false,
      })
    } else {
      infoWindow.current?.close()
    }
  }, [open])

  useEffect(() => {
    if (infoWindow.current) {
      infoWindow.current.setContent(
        renderToString(
          <div>
            {children}
          </div>
        )
      )
    }
  }, [infoWindow, children])

  return null
}
