import React from 'react'
import { useEffect, useState } from 'react'
import { useDebouncedValue } from '@mantine/hooks'
import { TextInput } from '@mantine/core'

interface GeocoderInputProps {
  onChange(result: google.maps.GeocoderResult): void,
}

export const GeocoderInput: React.FC<GeocoderInputProps> = ({ onChange }) => {
  const [value, setValue] = useState('')
  const [description, setDescription] = useState('')
  const [error, setError] = useState('')
  const [debounced] = useDebouncedValue(value, 1000)

  useEffect(() => {
    // Guards
    if (!window.google) return
    if (!value) return

    // Try to translate the return to a value
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode( { 'address': debounced}, function(results, status) {
      if (status === 'OK' && results) {
        const formatted = results[0].formatted_address
        const latLong = [
          results[0].geometry.location.lat(),
          results[0].geometry.location.lng(),
        ]
        setDescription(`${formatted} (${latLong[0].toFixed(2)}, ${latLong[1].toFixed(2)})`)
        setError('')
        onChange && onChange(results[0])
      } else {
        setError('Unrecognized location')
        setDescription('')
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced])

  return (
    <>
      <TextInput
        label="Campgrounds near... (address or zip)"
        description={description}
        error={error}
        size='xs'
        required
        value={value}
        style={{ flex: 1 }}
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </>
  )
}