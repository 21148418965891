import React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  Group,
  Stack,
} from '@mantine/core'

import { CampgroundsMap } from './campgroundsMap'
import { CampgroundsTable } from './campgroundsTable'
import { GeocoderInput } from '../../components/geocoderInput'

import {
  setSearchResults,
  useLazyFetchCampgroundsQuery,
} from './campgroundsSlice'

export function Campgrounds() {
  const dispatch = useDispatch()
  const [ trigger, data ] = useLazyFetchCampgroundsQuery()

  function geocoderChanged(geocode: google.maps.GeocoderResult) {
    const latLng = {
      lat: geocode.geometry.location.lat(),
      lng: geocode.geometry.location.lng(),
    }
    window.campwatch.map.setZoom(8)
    window.campwatch.map.panTo(latLng)
    trigger({
      variables: {
        latitude: latLng.lat,
        longitude: latLng.lng,
      },
    }, true /* preferCacheValue */)
  }

  useEffect(() => {
    if (data.isSuccess && data.data !== undefined) {
      dispatch(setSearchResults(data.data))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Group style={{height: '100%'}}>
      <Stack style={{height: '100%', maxWidth: 400 }} justify='flex-start'>
        <GeocoderInput onChange={geocoderChanged} />
        <CampgroundsTable style={{ flex: 1, flexGrow: 1 }}/>
      </Stack>
      <CampgroundsMap style={{ flex: 1, flexGrow: 1, height: '100%' }} />
    </Group>
  )
}
