import { createSlice, PayloadAction } from '@reduxjs/toolkit'

//
// Auth Slice
//
interface AuthSliceState {
  token?: string | null,
}
export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
  } as AuthSliceState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
  },
})

export const {
  setToken,
} = authSlice.actions

export const tokenSelector = () =>
  (state: any) => state.auth.token

export const reducer = authSlice.reducer
