import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as dayjs from 'dayjs'

import { Stack, Group, Button } from '@mantine/core'

import {
  setUrlState,
  setCreateOpen,
  createUrlSelector,
  createOpenSelector,
  useCreateWatchMutation,
  useCreateCampgroundWatchMutation,
  WatchesUrlState,
} from './watchesSlice'

import { WatchesTable } from './watchesTable'
import { CreateWatchModal } from './createWatchModal'

export const Watches : React.FC = () => {
  const dispatch = useDispatch()
  const open = useSelector<any, boolean>(createOpenSelector())
  const value = useSelector<any, WatchesUrlState>(createUrlSelector())

  const [ createWatch ] = useCreateWatchMutation()
  const [ createCampgroundWatch ] = useCreateCampgroundWatchMutation()

  const handleClose = async (create: boolean) => {
    if (create && value) {
      const dates = []
      const start = dayjs(value.startDateStart)
      const end = dayjs(value.startDateEnd)
      for (let d = start; d.isBefore(end) || d.isSame(end); d = d.add(1, 'day')) {
        dates.push(d)
      }

      const data = {
        startDates: dates.map((d) => { return d.format('YYYY-MM-DD') }),
        minNights: value.minNights,
        maxNights: value.maxNights,
        minPeople: value.minPeople,
        maxPeople: value.maxPeople,
      }
      const watch = await createWatch(data)

      if ('data' in watch) {
        value.campgrounds && value.campgrounds.forEach((id) => {
          createCampgroundWatch({
            campground: id,
            watchID: watch.data.createWatch.id,
          })
        })
      }
    }

    dispatch(setCreateOpen(false))
  }

  return (
    <Stack>
      <Group>
        <Button onClick={() => dispatch(setCreateOpen(true))}>
          Create Watch
        </Button>
        <CreateWatchModal
          size='255%'
          opened={open}
          closeOnClickOutside={false}
          onClose={handleClose}
          onChange={(value) => dispatch(setUrlState({
            createOpen: open,
            startDateStart: value.startDateStart && value.startDateStart.valueOf(),
            startDateEnd: value.startDateEnd && value.startDateEnd.valueOf(),
            minNights: value.numNights && value.numNights[0],
            maxNights: value.numNights && value.numNights[1],
            minPeople: value.numPeople && value.numPeople[0],
            maxPeople: value.numPeople && value.numPeople[1],
            campgrounds: value.campgrounds && value.campgrounds.map((c) => c.id),
          }))}
        />
      </Group>
      <WatchesTable />
    </Stack>
  )
}
