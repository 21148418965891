import React from 'react'
import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { useAuth0 } from '@auth0/auth0-react'

import { useColorScheme, useViewportSize } from '@mantine/hooks'
import { NotificationsProvider } from '@mantine/notifications'
import { AppShell, MantineProvider, ColorSchemeProvider } from '@mantine/core'

import { Navbar } from './components/navbar'
import { setToken, tokenSelector } from 'src/features/auth/authSlice'

import './App.css'

export function App() {
  // hook will return either 'dark' or 'light' on client side
  // and always 'light' during ssr as window.matchMedia is not available
  const preferredColorScheme = useColorScheme('dark')
  const [colorScheme, setColorScheme] = useState(preferredColorScheme)
  const toggleColorScheme = (value?: React.SetStateAction<'light'|'dark'>) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  const dispatch = useDispatch()
  const token = useSelector(tokenSelector())
  const { height } = useViewportSize()
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently({})
        dispatch(setToken(token))
      } catch (e) {
        console.error(e);
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently])

  if (!token) {
    return <div>No token set</div>
  }

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{ colorScheme }}>
        <NotificationsProvider>
          <AppShell
            styles={(theme) => ({
              main: {
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                height: height,
              },
            })}
            fixed
            navbar={<Navbar/>}
          >
            <Outlet />
          </AppShell>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  )
}
