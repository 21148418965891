import reportWebVitals from './reportWebVitals'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { Routes, Route } from 'react-router-dom'

import { Provider } from 'react-redux'

import { Auth0Provider } from "@auth0/auth0-react"

import { HistoryRouter as Router } from 'redux-first-history/rr6'

import { store, history } from './app/store'


import { ProtectedRoute, Loader } from './features/auth/protectedRoute'

import { App } from './App'
import { Watches } from './features/watches/watches'
import { Campgrounds } from './features/create/campgrounds'

import './index.css'

console.info('To turn on debug: window.LOG_LEVEL = \'DEBUG\'')

window.campwatch = {}

const container = document.getElementById('root')!
const root = createRoot(container)!

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN!}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
          redirectUri={window.location.origin}
          audience='https://campwatch.milkbar.com/api/v2'
          scope='read:watches'
          cacheLocation='localstorage'
        >
          <Routes>
            <Route
              path='/'
              element={<ProtectedRoute component={App}/>}
            >
              <Route
                path='watches/*'
                element={<Watches/>}
              />
              <Route
                path='campgrounds/*'
                element={<Campgrounds/>}
              />
            </Route>
            <Route
              path='/login'
              element={<Loader/>}
            />
          </Routes>
        </Auth0Provider>
      </Router>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
