import { gql } from 'graphql-request'

export const campgrounds = gql`
  query Campgrounds(
    $latitude: String
    $longitude: String
    $name: String
    $siteTypes: [Int]
    $state: String
    $amenities: [Int]
    $maxPeople: Int
    $waterfront: Boolean
  ) {
    campgrounds(
      latitude: $latitude
      longitude: $longitude
      name: $name
      siteTypes: $siteTypes
      state: $state
      amenities: $amenities
      maxPeople: $maxPeople
      waterfront: $waterfront
    ) {
      id
      name
      latitude
      longitude
      thumbnail
      source
      state
    }
  }
`

export const getWatch = gql`
  query GetWatch($id: ID!) {
    getWatch(id: $id) {
      startDates
      minNights
      maxNights
      campgrounds {
        items {
          campground
          sites
          id
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      minPeople
      maxPeople
      id
      createdAt
      updatedAt
      owner
    }
  }
`

export const listWatches = gql`
  query ListWatches(
    $filter: TableWatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        startDates
        minNights
        maxNights
        campgrounds {
          nextToken
        }
        minPeople
        maxPeople
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`

export const getCampgroundWatch = gql`
  query GetCampgroundWatch($id: ID!) {
    getCampgroundWatch(id: $id) {
      campground
      sites
      watch {
        startDates
        minNights
        maxNights
        campgrounds {
          nextToken
        }
        minPeople
        maxPeople
        id
        createdAt
        updatedAt
        owner
      }
      id
      createdAt
      updatedAt
      owner
    }
  }
`

export const listCampgroundWatches = gql`
  query ListCampgroundWatches(
    $filter: TableCampgroundWatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampgroundWatches(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        campground
        sites
        watch {
          startDates
          minNights
          maxNights
          minPeople
          maxPeople
          id
          createdAt
          updatedAt
          owner
        }
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
