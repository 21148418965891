/* eslint-disable */

export const createWatch = /* GraphQL */ `
  mutation CreateWatch(
    $input: CreateWatchInput!
  ) {
    createWatch(input: $input) {
      startDates
      minNights
      maxNights
      campgrounds {
        items {
          campground
          sites
          id
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      minPeople
      maxPeople
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateWatch = /* GraphQL */ `
  mutation UpdateWatch(
    $input: UpdateWatchInput!
  ) {
    updateWatch(input: $input) {
      startDates
      minNights
      maxNights
      campgrounds {
        items {
          campground
          sites
          id
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      minPeople
      maxPeople
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteWatch = /* GraphQL */ `
  mutation DeleteWatch(
    $input: DeleteWatchInput!
  ) {
    deleteWatch(input: $input) {
      startDates
      minNights
      maxNights
      campgrounds {
        items {
          campground
          sites
          id
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      minPeople
      maxPeople
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCampgroundWatch = /* GraphQL */ `
  mutation CreateCampgroundWatch(
    $input: CreateCampgroundWatchInput!
  ) {
    createCampgroundWatch(input: $input) {
      campground
      sites
      watch {
        startDates
        minNights
        maxNights
        campgrounds {
          nextToken
        }
        minPeople
        maxPeople
        id
        createdAt
        updatedAt
        owner
      }
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCampgroundWatch = /* GraphQL */ `
  mutation UpdateCampgroundWatch(
    $input: UpdateCampgroundWatchInput!
  ) {
    updateCampgroundWatch(input: $input) {
      campground
      sites
      watch {
        startDates
        minNights
        maxNights
        campgrounds {
          nextToken
        }
        minPeople
        maxPeople
        id
        createdAt
        updatedAt
        owner
      }
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCampgroundWatch = /* GraphQL */ `
  mutation DeleteCampgroundWatch(
    $input: DeleteCampgroundWatchInput!
  ) {
    deleteCampgroundWatch(input: $input) {
      campground
      sites
      watch {
        startDates
        minNights
        maxNights
        campgrounds {
          nextToken
        }
        minPeople
        maxPeople
        id
        createdAt
        updatedAt
        owner
      }
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
