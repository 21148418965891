import React from 'react'
import { useState, useEffect } from 'react'

import {
  Stack,
  Modal,
  ModalProps,
  Button,
  Group,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'


import { CampgroundsMap } from '../create/campgroundsMap'
import { PeopleRangeSlider } from '../../components/numberOfPeopleSlider'
import { CampgroundsMultiSelect } from '../create/campgroundsMultiSelect'

import { Campground } from '../create'

export interface CreateWatchValue {
  numPeople: [number, number] | null,
  startDateStart: Date | null,
  startDateEnd: Date | null,
  numNights: [number, number] | null,
  campgrounds: Campground[] | null,
}

interface CreateWatchModalProps extends Omit<ModalProps, 'onClose' | 'onChange' | 'withCloseButton'> {
  onClose(create: boolean): void,
  onChange(value: CreateWatchValue): void,
}
export const CreateWatchModal: React.FC<CreateWatchModalProps> = ({
  onClose,
  onChange,
  ...props
}) => {
  const [numPeople, setNumPeople] = useState<[number, number] | null>([1, 1])
  const [numNights, setNumNights] = useState<[number, number] | null>([1, 1])
  const [startDateEnd, setStartDateEnd] = useState<Date | null>(new Date())
  const [startDateStart, setStartDateStart] = useState<Date | null>(new Date())
  const [campgrounds, setCampgrounds] = useState<Campground[]>([])

  useEffect(() => {
    onChange({
      numPeople: numPeople,
      numNights: numNights,
      startDateStart: startDateStart,
      startDateEnd: startDateEnd,
      campgrounds: campgrounds,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numPeople, numNights, startDateEnd, startDateStart, campgrounds])

  return (
    <Modal
      title='Create Watch'
      onClose={() => onClose(false)}
      withCloseButton={false}
      {...props}
    >
      <Group noWrap>
        <Stack style={{height: '50vh'}} justify='flex-start'>
          <Group>
            <DatePicker
              size='xs'
              required
              allowFreeInput
              value={startDateStart}
              label='Check In From'
              placeholder='Pick a date'
              allowLevelChange={false}
              minDate={new Date()}
              onChange={setStartDateStart}
            />
            <DatePicker
              size='xs'
              required
              allowFreeInput
              value={startDateEnd}
              label='Check In To'
              placeholder='Pick a date'
              allowLevelChange={false}
              minDate={new Date()}
              onChange={setStartDateEnd}
            />
          </Group>
          <PeopleRangeSlider
            value={numNights || [1, 1]}
            sx={{flexGrow: 10}}
            onChange={setNumNights}
          />
          <PeopleRangeSlider
            value={numPeople || [1, 1]}
            sx={{flexGrow: 10}}
            onChange={setNumPeople}
          />
          <CampgroundsMultiSelect
            required
            clearable
            data-autofocus
            size='xs'
            id='campgrounds'
            type='email'
            label='Campgrounds'
            placeholder='Campground name'
            style={{maxWidth: 500}}
            onChange={setCampgrounds}
          />
          <Group position='right'>
            <Button variant='outline' onClick={() => onClose(false)}>Cancel</Button>
            <Button variant='filled' onClick={() => onClose(true)}>Create</Button>
          </Group>
        </Stack>
        <CampgroundsMap style={{ flex: 1, flexGrow: 1, height: '50vh'}} />
      </Group>
      </Modal>
  )
}
