import React from 'react'
import {
  Group,
  Stack,
  Text,
  ScrollArea,
  Navbar as MantineNavbar,
  Box,
  ActionIcon,
  useMantineTheme,
  useMantineColorScheme,
} from '@mantine/core'

import {
  ChevronsLeft,
  ChevronsRight
} from 'tabler-icons-react'

import { User } from './user'
import { MainLinks } from './mainLinks'

export const Navbar = () => {
  const theme = useMantineTheme()
  const { colorScheme } = useMantineColorScheme()
  const dark = colorScheme === 'dark'

  return (
    <MantineNavbar p="xs" width={{ base: 250 }}>
      <MantineNavbar.Section
        sx={{
          paddingBottom: theme.spacing.sm,
          borderBottom: `1px solid ${
            dark ? theme.colors.dark[4] : theme.colors.gray[2]
          }`,
        }}
      >
        <Group position='apart'>
          <Text color="gray" size='xl' >
            CampWatch
          </Text>
          {/* TODO: Hook this up to expand/collapse the navbar */}
          <ActionIcon variant="outline" size="sm">
            {dark ? <ChevronsLeft size={16} /> : <ChevronsRight size={16} />}
          </ActionIcon>
        </Group>
      </MantineNavbar.Section>

      <MantineNavbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        <Box py="md">
          <MainLinks />
        </Box>
      </MantineNavbar.Section>
      <MantineNavbar.Section
        sx={{
          paddingTop: theme.spacing.sm,
          borderTop: `1px solid ${
            dark ? theme.colors.dark[4] : theme.colors.gray[2]
          }`,
        }}
      >
        <Stack>
          <User />
        </Stack>
      </MantineNavbar.Section>
    </MantineNavbar>
  )
}
