import React from 'react'
import {
  ActionIcon,
  Group,
  Stack,
  RangeSlider,
  RangeSliderProps,
  Text
} from '@mantine/core';
import { User, Users } from 'tabler-icons-react'

export const PeopleRangeSlider: React.FC<RangeSliderProps> = ({
  value,
  min = 1,
  max = 30,
  step = 1,
  onChange,
  onChangeEnd,
  ...others
}) => {
  const decrement = () => {
    if(!value) return
    const update : [number, number] = [
      Math.max(value[0]-step, min),
      Math.max(value[1]-step, min),
    ]
    if(onChange) onChange(update)
    if(onChangeEnd) onChangeEnd(update)
  }

  const increment = () => {
    if (!value) return
    const update : [number, number] = [
      Math.min(value[0]+step, max),
      Math.min(value[1]+step, max),
    ]
    if(onChange) onChange(update)
    if(onChangeEnd) onChangeEnd(update)
  }

  return (
    <Stack>
      <Text size='xs'>Number of People</Text>
      <Group position='apart'>
        <ActionIcon onClick={decrement}>
          <User />
        </ActionIcon>
        <RangeSlider
          min={min}
          max={max}
          step={step}
          minRange={0}
          value={value}
          onChange={onChange}
          onChangeEnd={onChangeEnd}
          {...others}
        />
        <ActionIcon onClick={increment}>
          <Users />
        </ActionIcon>
        <Text style={{ width: '4em'}}>
          {
            (value)
            ? (value[0] === value[1])
              ? `${value![0]}`
              : `${value![0]}-${value![1]}`
            : '1'
          }
        </Text>
      </Group>
    </Stack>
  )
}
