import { withAuthenticationRequired } from "@auth0/auth0-react"
import React, { ComponentType } from "react"

import { Center } from '@mantine/core'

export const Loader = () => {
  return <Center>
    Redirecting to the login page.
  </Center>
}

interface ProtectedRouteProps {
  component: ComponentType
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loader />,
  })

  return <Component />
}