import React from 'react'
import { NavLink } from "react-router-dom"

import { ThemeIcon, UnstyledButton, Group, Text } from '@mantine/core'
import { Plus, Tent } from 'tabler-icons-react'

interface MainLinkProps {
  icon: React.ReactNode,
  color: string,
  label: string,
  href: string,
}

const MainLink: React.FC<MainLinkProps> = ({ icon, color, label, href }) => {
  return (
    <UnstyledButton
      component={NavLink}
      to={href}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
      })}
    >
      <Group>
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>

        <Text size="sm">{label}</Text>
      </Group>
    </UnstyledButton>
  )
}

const data = [
  { icon: <Tent size={16} />, color: 'blue', label: 'Watches', href: '/watches' },
  { icon: <Plus size={16} />, color: 'teal', label: 'New Watch', href: '/campgrounds' },
]

export const MainLinks: React.FC = () => {
  const links = data.map((link) => <MainLink {...link} key={link.label} />)
  return <div>{links}</div>
}
